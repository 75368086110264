<template>
  <div class="tooltip">
    <svg-icon class-name="tooltip-icon" icon-name="info"/>
    <div class="tooltip-content">
      <div class="tooltip-title">
        {{title}}
      </div>
      <div class="tooltip-text">
        {{text}}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@shared/components/SvgIcon'
export default {
  name: 'TooltipIcon',
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
  components: { SvgIcon },
  mounted () {
    const contentEl = this.$el.querySelector('.tooltip-content')
    const contentMaxLength = this.title.length > this.text.length ? this.title.length : this.text.length
    if (contentMaxLength > 20) {
      const width = 7 * contentMaxLength > 500 ? 500 : 7 * contentMaxLength
      contentEl.style.width = `${width}px`
    }
    const contentWidth = contentEl.clientWidth
    contentEl.style.left = `calc(50% - ${contentWidth / 2}px)`
  },
}
</script>

<style lang="scss" scoped>
.tooltip {
  cursor: pointer;
  position: relative;
  margin: 0 7px;
  z-index: 1;
  display: flex;
  justify-items: center;

  &-icon {
    fill: $color-primary;;
  }

  &-content {
    padding: 16px;
    font-size: 14px;
    line-height: 16px;
    color: $text-black;
    background-color: $color-hover;
    position: absolute;
    left: 0;
    bottom: 24px;
    pointer-events: none;
    opacity: 0.01;
    transition: opacity 0.2s ease;
    white-space: normal;
  }

  &:hover &-content {
    opacity: 1;
    pointer-events: all;
  }

  &-title {
    font-weight: 700;
    margin-bottom: 8px;
  }

  &-text {
    font-weight: 400;
    width: 100%;
  }
}

.edit-variation-modal--footer .tooltip,
.update-price--footer .tooltip {
  margin-left: 0;

  &-icon {
    fill: $text-gray;
  }
}
</style>
