<template>
  <div class="main-content__with-sidebar">
    <StepsContainer
      :steps="steps"
      :style="{height: '92px'}"
    />
    <div class="main-content__inner">
      <TheModel
        v-if="isModelEdit"
        :model="newModel.model"
        :selectedBrand="selectedBrand"
        :selectedType="selectedType"
        @setModelValue="setValue"
        @onTypeSelect="onTypeSelect"
        @onBrandSelect="onBrandSelect"
        @onSelectCategory="onSelectCategory"
        @onButtonClick="updateModel"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheModel from '@/components/ui/layout/models/TheModel'
import StepsContainer from '@/components/ui/layout/steps/StepsContainer'

export default {
  name: 'EditModel',
  components: { StepsContainer, TheModel },
  data () {
    return {
      selectedType: {},
      selectedBrand: {},
      steps: [
        {
          id: 1,
          name: this.$t('editModel'),
          isCurrent: true,
          isDone: false,
          hasLine: false,
        },
      ],
    }
  },
  async mounted () {
    const model = await this.$store.dispatch('getModelById', this.$route.params.modelId)
    if (model.isOwner === false) {
      this.$router.push('/goods/1')
    }
    await this.$store.dispatch('setExistenceModel', model)
    this.selectedBrand = {
      id: model.brand.id,
      label: model.brand.name,
    }

    if (model.type) {
      this.selectedType = {
        id: model.type.id,
        label: model.type.name,
      }
    }
  },
  computed: {
    ...mapGetters([
      'newModel',
      'isModelEdit',
    ]),
  },
  methods: {
    onTypeSelect (type) {
      this.selectedType = type
      this.$store.dispatch('setModelType', type)
    },
    onBrandSelect (brand) {
      this.selectedBrand = brand
      this.$store.dispatch('setModelBrand', brand)
    },
    onSelectCategory (category) {
      this.$store.dispatch('setModelCategory', category)
    },
    setValue (payload) {
      this.$store.commit('removeAllErrors')
      this.$store.commit('setNewModelValues', payload)
    },
    async updateModel () {
      await this.$store.dispatch('modifyModel')
      await this.$router.push(`/create/product/${this.$route.params.modelId}#new`)
    },
  },
}
</script>
